import { ClientRequestI } from '../../../../models/Client/Client'
import { ClientFormType, CompanyContactsInfo } from '../../../../models/Company/Company'

export const setClientContactData = (data: ClientFormType, identifier = false): CompanyContactsInfo[] => {
  const contactTypes = ['financeContact', 'operationalContact', 'commercialContact', 'managementContact']
  if (!data.contacts) return []
  const contactRequestTypes = {
    financeContact: 'FINANCEIRO',
    operationalContact: 'OPERACIONAL',
    commercialContact: 'COMERCIAL',
    managementContact: 'DIRETORIA',
  }

  return contactTypes.map((contactType) => {
    const contact = data.contacts[contactType as keyof typeof data.contacts]
    const contactInfo: CompanyContactsInfo = {
      name: contact && contact.name ? contact.name : '',
      surname: contact && contact.surname ? contact.surname : '',
      telephone: contact && contact.telephone ? contact.telephone : '',
      email: contact && contact.email ? contact.email : '',
      type: contactRequestTypes[contactType as keyof typeof contactRequestTypes],
    }
    if (identifier) {
      contactInfo.identifier = contact ? contact.identifier : undefined
    }
    return contactInfo
  })
}

const setRequestData = (data: ClientFormType): ClientRequestI => {
  const companyRequestInfo: ClientRequestI = {
    name: data.corporateName,
    companyName: data.companyName,
    cnpj: data.cnpj.replaceAll(/[^0-9]/g, ''),
    greenMonitorLink: data?.greenMonitorLink,
    address: {
      zipcode: data.cep,
      state: data.state,
      city: data.city,
      district: data.district,
      street: data.street,
      number: data.number,
      complement: data.complement,
    },
    mtrSystemAccess: {
      mtrSystemName: data.system,
      mtrSystemPass: data.password,
      mtrSystemUser: data.cpf,
      mtrSystemUnitCode: data.branch,
    },
  }
  return companyRequestInfo
}

export default setRequestData
