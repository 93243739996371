import { Button, Card, Checkbox, Grid, List, ListItem, ListItemText, Popover } from '@mui/material'
import _ from 'lodash'
import { ArrowDropDown } from '@material-ui/icons'
import IconButton from '@mui/material/IconButton'
import FilterListIcon from '@mui/icons-material/FilterList'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import FileDownload from 'js-file-download'
import AppContext, { AppContextType } from '../../../AppContext'
import DataTable from '../../../components/DataTable/DataTable'
import Gathering, {
  CancelGathering,
  GatheringPaginated,
  GatheringToDatatable,
  mtrStateEnum,
} from '../../../models/Gathering/Gathering'
import { CompanyMinimalInfo } from '../../../models/Company/Company'
import {
  cancelGathering,
  downloadGatheringsPDF,
  downloadGatheringsCDF,
  getAllGatherings,
  retryGathering,
} from '../../../services/Gathering/GatheringService'
import CancelGatheringDialog from './CancelGatheringDialog/CancelGatheringDialog'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { Order } from '../../../utils/sort'
import GatheringFilterDrawer, { GatheringParams } from './GatheringFilterDrawer/GatheringFilterDrawer'
import useStyles from './GatheringListStyles'
import Icons from '../../../assets/icons/svgIcons'
import { ApplicationColors } from '../../../utils/applicationColors'
import PopOverMessage from '../../../components/PopOver/PopOver'
import { featureAccessCheck } from '../../../utils/AccessPermissionsCheck'
import { getAllClients } from '../../../services/Client/ClientService'
import { getPartnersByCustomerIdentifier } from '../../../services/Client/ClientPartnerService'
import { unmaskCNPJ } from '../../../utils/masks/maskCNPJ'
import InputSelectCompany, { SelectCompanyOptions } from '../../../components/Form/Select/SelectCompany'
import getInitialSelectedCompany from '../../../utils/getInitialSelectedCompany'
import FeamStatusSign from '../../../components/FeamStatusSign/FeamStatusSign'

const GatheringList: React.FC = () => {
  const classes = useStyles()
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [totalItems, setTotalItems] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(25)
  const [sort, setSort] = React.useState<Order>('DESC' as Order)
  const [sortBy, setSortBy] = React.useState<string>('')
  const [gatherings, setGatherings] = React.useState<GatheringToDatatable>({
    content: [],
    currentPage: 0,
  })
  const [loading, setLoading] = React.useState<boolean>(false)
  const [isCancelGatheringDialogOpen, setIsCancelGatheringDialogOpen] = React.useState<boolean>(false)
  const [cancelGatheringDialogData, setCancelGatheringDialogData] = React.useState<CancelGathering | undefined>(
    undefined
  )
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [openFilter, setOpenFilter] = React.useState<boolean>(false)
  const [activeGathering, setActiveGathering] = React.useState<number | undefined>(undefined)
  const [filterParams, setFilterParams] = React.useState<GatheringParams>({ generatorCnpj: '' })
  const [customersMap, setCustomersMap] = React.useState<CompanyMinimalInfo[]>([])
  const [partnersMap, setPartnersMap] = React.useState<CompanyMinimalInfo[]>([])
  const [selectedGenerator, setSelectedGenerator] = React.useState<CompanyMinimalInfo>(customersMap[0])
  const [selectedTransporter, setSelectedTransporter] = React.useState<CompanyMinimalInfo>()
  const [selectedReceiver, setSelectedReceiver] = React.useState<CompanyMinimalInfo>()
  const {
    setTitle,
    setCustomHeaderContent,
    showAlert,
    setIsShowLoading,
    setItemsBreadCrumb,
    defaultCompany,
    setDefaultCompany,
  } = React.useContext(AppContext as React.Context<AppContextType>)
  const navigate = useNavigate()
  const [selectedGatheringIds, setSelectedGatheringIds] = React.useState<number[]>([])

  const getCustomers = async (): Promise<CompanyMinimalInfo[] | null> => {
    return getAllClients()
      .then((clients) => clients)
      .catch(() => {
        showAlert('error', 'Erro ao carregar clientes.')
        return null
      })
  }

  const setCustomersInfos = (customers: CompanyMinimalInfo[] | null): void => {
    if (customers) {
      setCustomersMap(customers)
      const initialSelectedCustomer = getInitialSelectedCompany(customers, defaultCompany)
      setFilterParams({
        ...filterParams,
        generatorCnpj: unmaskCNPJ(initialSelectedCustomer.cnpj),
        orderDirection: 'DESC' as Order,
      })
      setSelectedGenerator(initialSelectedCustomer)
    }
  }

  const setPartnersInfos = (partners: CompanyMinimalInfo[] | null): void => {
    if (partners && partners.length > 0) {
      setPartnersMap(partners)
    } else {
      setPartnersMap([])
    }
  }

  const handleChangeGenerator = async (
    event: React.SyntheticEvent,
    value: SelectCompanyOptions | null
  ): Promise<void> => {
    if (value) {
      setSelectedGenerator(value)
      setFilterParams({ ...filterParams, generatorCnpj: unmaskCNPJ(value.cnpj) })
      const partners = await getPartnersByCustomerIdentifier(value.identifier)
      setPartnersInfos(partners)
    }
  }

  const handleClickNew = React.useCallback((): void => {
    navigate('/main/gathering/form')
  }, [navigate])

  const customHeaderContent = React.useMemo(
    () =>
      featureAccessCheck('mtr-write') ? (
        <IconButton aria-label="Novo MTR" onClick={handleClickNew} size="large" data-cy="go-to-gathering-form-button">
          <Icons.Add fill="white" />
        </IconButton>
      ) : (
        <div />
      ),
    [handleClickNew]
  )

  const handleInit = React.useCallback(async (): Promise<void> => {
    setTitle('Listar MTRs')
    setCustomHeaderContent(customHeaderContent)
    try {
      const customers = await getCustomers()
      if (!customers || customers.length === 0) {
        showAlert('warning', 'Usuário sem vínculos com clientes. Contate o time operacional.')
      } else {
        setCustomersInfos(customers)
        const customerIdentifier = defaultCompany ? defaultCompany.identifier : customers[0].identifier
        const partners = await getPartnersByCustomerIdentifier(customerIdentifier)
        setPartnersInfos(partners)
      }
    } catch {
      showAlert('error', 'Erro ao carregar informações da empresa.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customHeaderContent, setCustomHeaderContent, setTitle, showAlert])

  React.useEffect(() => {
    handleInit()
  }, [handleInit, setLoading])

  const checkboxClickHandler = (gatheringId: number): void => {
    const filterSelectedGatheringsByGatheringId = (_selectedGatherings: number[]): number[] => {
      return _selectedGatherings.filter((id) => id !== gatheringId)
    }
    const addGatheringIdToSelectedGatherings = (_selectedGatherings: number[]): number[] => {
      return [...selectedGatheringIds, gatheringId]
    }
    if (selectedGatheringIds.includes(gatheringId)) {
      setSelectedGatheringIds(filterSelectedGatheringsByGatheringId)
    } else {
      setSelectedGatheringIds(addGatheringIdToSelectedGatherings)
    }
  }

  const isGatheringSelectedInPage = (id: number): boolean => {
    return selectedGatheringIds.includes(id)
  }

  const areAllGatheringsSelectedInPage = (): boolean => {
    const pageGatheringsIds = gatherings.content.map((gathering) => gathering.idCollections)
    const areSameArrayAndNotEmpty =
      _.isEmpty(_.xor(pageGatheringsIds, selectedGatheringIds)) && selectedGatheringIds.length > 0
    return areSameArrayAndNotEmpty
  }

  const checkboxAllClickHandler = (): void => {
    const pageGatheringsIds: number[] = gatherings.content
      .map((gathering) => gathering.idCollections)
      .filter((id): id is number => id !== undefined)
    if (areAllGatheringsSelectedInPage()) {
      setSelectedGatheringIds([])
    } else {
      setSelectedGatheringIds(pageGatheringsIds)
    }
  }

  const gatheringsMap = [
    {
      key: 'idCollections',
      value: (
        <Checkbox
          sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
          checked={areAllGatheringsSelectedInPage()}
          onChange={() => checkboxAllClickHandler()}
        />
      ),
      transform: (idCollections: number) => (
        <Checkbox
          sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
          checked={isGatheringSelectedInPage(idCollections)}
          onChange={() => checkboxClickHandler(idCollections)}
        />
      ),
    },
    {
      key: 'manifestEmissionDate',
      value: 'Data de Emissão',
      transform: (date: string | null): string => {
        return date === null ? '-' : new Date(date).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      },
    },
    { key: 'manifestCod', value: 'Manifesto' },
    { key: 'transporterName', value: 'Transportador' },
    { key: 'receiverName', value: 'Destinador' },
    { key: 'mtrState', value: 'Status Integração' },
  ]

  const standardErrors = (mtrs: GatheringPaginated): GatheringToDatatable => {
    const mtrList = {
      content: mtrs.content.map((mtr) => {
        const g = mtr
        if (g.mtrState.includes('401')) {
          g.mtrState = mtrStateEnum.IP_ERROR
        } else if (g.mtrState.includes('503')) {
          g.mtrState = mtrStateEnum.FEAM_ERROR
        } else if (g.mtrState.includes('50')) {
          g.mtrState = mtrStateEnum.INTERNAL_ERROR
        } else if (g.mtrState.includes('[404] during [GET]')) {
          g.mtrState = mtrStateEnum.BAD_CREDENTIALS
        } else if (g.mtrState.toLowerCase().includes('amazon')) {
          g.mtrState = mtrStateEnum.SERVER_ERROR
        } else if (g.mtrState.toLowerCase().includes('error')) {
          g.mtrState = mtrStateEnum.UNKNOWN_ERROR
        } else if (g.mtrState.includes('[404 Not Found] during [POST]')) {
          g.mtrState = mtrStateEnum.FEAM_COMMUNICATION
        }
        return g
      }),
      currentPage: mtrs.pageable.pageNumber,
    }
    return mtrList
  }

  const onSort = (order: Order, property: string): void => {
    setSort(order)
    setSortBy(property)
  }

  const handleClose = (): void => {
    setActiveGathering(undefined)
    setCancelGatheringDialogData(undefined)
    setIsCancelGatheringDialogOpen(false)
  }

  const handleOpenCancelDialog = (row: Gathering): void => {
    if (!row.manifestCod) {
      showAlert('error', 'Manifesto não possui código. Não é possível cancelar.')
      return
    }
    setActiveGathering(row.idCollections)
    const cancelGatheringData: CancelGathering = {
      generatorDocument: row.generatorDocument,
      justify: '',
      manifestCode: row.manifestCod,
    }
    setCancelGatheringDialogData(cancelGatheringData)
    setIsCancelGatheringDialogOpen(true)
  }

  const fetchData = useCallback(async (): Promise<void> => {
    const handlePromise = (promise: Promise<GatheringPaginated>): void => {
      setLoading(true)
      promise
        .then((res: GatheringPaginated) => {
          if (res.content.length === 0) showAlert('info', 'Nenhum MTR registrado para esse cliente.')
          setGatherings(standardErrors(res))
          setTotalItems(res.totalElements)
        })
        .catch(() => {
          showAlert('error', 'Erro ao carregar MTRs.')
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (filterParams.generatorCnpj) {
      filterParams.orderDirection = filterParams.orderDirection?.toUpperCase() as Order
      if (!filterParams.manifestCode || filterParams.manifestCode === '') delete filterParams.manifestCode
      handlePromise(getAllGatherings(filterParams))
    }
  }, [filterParams, showAlert])

  const handleRetry = (idCollections: number): void => {
    setIsShowLoading(true)
    retryGathering(idCollections)
      .then(() => {
        showAlert('success', 'PDF gerado com sucesso.')
        setIsShowLoading(false)
      })
      .catch((error) => {
        if (error.response?.data?.errorType) {
          navigate('/main/gathering/list')
          showAlert('warning', error.response?.data?.errorMessage ?? 'Erro ao salvar MTR. Tente novamente mais tarde.')
        } else {
          showAlert('error', 'Erro ao gerar PDF.')
        }
        setIsShowLoading(false)
      })
      .finally(() => fetchData())
  }

  const handleSave = (cancelGatheringData: CancelGathering): void => {
    setIsShowLoading(true)
    cancelGathering(cancelGatheringData)
      .then(() => {
        setGatherings({
          content: gatherings.content.map((g) => {
            if (g.idCollections === activeGathering) {
              return { ...g, mtrState: mtrStateEnum.CANCELED }
            }
            return g
          }),
          currentPage: gatherings.currentPage,
        })
        setActiveGathering(undefined)
        showAlert('success', 'MTR cancelado com sucesso.')
        setIsShowLoading(false)
      })
      .catch(() => {
        setActiveGathering(undefined)
        showAlert('error', 'Erro ao cancelar MTR. Tente novamente mais tarde.')
        setIsShowLoading(false)
      })
  }

  const downloadMTRs = (ids: number[]): void => {
    if (ids.length > 0) {
      downloadGatheringsPDF(ids)
        .then((response: Blob) => FileDownload(response, 'mtr.zip', 'application/zip'))
        .catch(async (response: Blob) => showAlert('error', await response.text()))
    } else {
      showAlert(
        'info',
        'Para baixar MTRs, você precisa selecionar ao menos um MTR. Basta clicar na caixa de seleção na lista de MTRs.'
      )
    }
  }

  const downloadCDFs = (ids: number[]): void => {
    if (ids.length > 0) {
      downloadGatheringsCDF(ids)
        .then((response: Blob) => FileDownload(response, 'cdf.zip', 'application/zip'))
        .catch(async (response: Blob) => showAlert('error', await response.text()))
    } else {
      showAlert(
        'info',
        'Para baixar CDFs, você precisa selecionar ao menos um MTR. Basta clicar na caixa de seleção na lista de MTRs.'
      )
    }
  }

  const handleCloseFilter = (): void => {
    setOpenFilter(false)
  }

  const handleCloseActionOptions = (): void => {
    setAnchorEl(null)
  }

  const handleOpenActionOptions = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  React.useEffect(() => {
    if (pageIndex !== 0) {
      setPageIndex(0)
    } else {
      setFilterParams({ ...filterParams, pageIndex, pageSize })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  React.useEffect(() => {
    setFilterParams({ ...filterParams, orderBy: sortBy, orderDirection: sort })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, sortBy])

  React.useEffect(() => {
    setFilterParams({ ...filterParams, pageIndex, pageSize })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex])

  React.useEffect(() => {
    if (loading) setIsShowLoading(true)
    if (!loading) setIsShowLoading(false)
  }, [loading])

  React.useEffect(() => {
    setDefaultCompany(selectedGenerator)
  }, [selectedGenerator])

  React.useEffect(() => {
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Listar MTRs', path: '/main/gathering/list' }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const MTRColumn = ({ row }: { row: Gathering }): JSX.Element => {
    if (row?.urlPdf) {
      return (
        <PopOverMessage
          element={
            <a href={`${row.urlPdf}`} target="_blank" rel="noreferrer" download="sample.PDF">
              <Icons.Download fill={ApplicationColors.primaryColor} />
            </a>
          }
          popOverMessage="Baixar MTR."
        />
      )
    }

    if (!row?.manifestCod || row?.manifestCod === '0000000000') {
      return (
        <PopOverMessage
          element={<Icons.Retry fill={ApplicationColors.primaryColor} />}
          popOverMessage="Tentar gerar novamente no órgão ambiental."
          handleClick={() => handleRetry(row.idCollections as number)}
        />
      )
    }

    return (
      <PopOverMessage
        element={<Icons.Retry fill={ApplicationColors.primaryColor} />}
        popOverMessage="Tentar gerar PDF do MTR."
        handleClick={() => handleRetry(row.idCollections as number)}
      />
    )
  }

  const CDFColumn = ({ row }: { row: Gathering }): JSX.Element => {
    if (row?.urlPdfCdf) {
      return (
        <PopOverMessage
          element={
            <a href={`${row.urlPdfCdf}`} target="_blank" rel="noreferrer" download="sample.PDF">
              <Icons.Download fill={ApplicationColors.primaryColor} />
            </a>
          }
          popOverMessage="Baixar CDF."
        />
      )
    }

    if (row?.mtrState.includes('Salvo')) {
      return (
        <PopOverMessage
          element={<Icons.Wait fill={ApplicationColors.primaryColor} />}
          popOverMessage="Aguardando CDF."
        />
      )
    }

    return (
      <PopOverMessage
        element={<Icons.DownloadOff fill={ApplicationColors.primaryColor} />}
        popOverMessage="CDF Indisponível."
      />
    )
  }

  const CancelColumn = ({ row }: { row: Gathering }): JSX.Element => {
    if (row?.mtrState === mtrStateEnum.WAITING_FEAM || row?.mtrState.includes('Salvo')) {
      return (
        <PopOverMessage
          element={<Icons.Cancel fill={ApplicationColors.primaryColor} />}
          popOverMessage="Cancelar MTR."
          handleClick={handleOpenCancelDialog}
          item={row}
        />
      )
    }

    if (row?.mtrState === mtrStateEnum.CANCELED) {
      return (
        <PopOverMessage
          element={<Icons.FileCanceled fill={ApplicationColors.primaryColor} />}
          popOverMessage="MTR cancelado."
        />
      )
    }

    return (
      <PopOverMessage
        element={<Icons.Warning fill={ApplicationColors.primaryColor} />}
        popOverMessage="Cancelamento Indisponível."
      />
    )
  }

  return (
    <>
      {featureAccessCheck('mtr-filter') && (
        <Grid container className={classes.filterContainer}>
          <Grid item className={classes.companyFilter}>
            <Card className="card-filters" variant="outlined">
              <InputSelectCompany
                id="generator"
                label="Empresa"
                company={selectedGenerator}
                options={customersMap}
                onChange={handleChangeGenerator}
              />
            </Card>
          </Grid>

          <Grid container className={classes.actionButtonContainer}>
            <Button
              variant="outlined"
              data-cy="mtr-filter-button"
              className={classes.actionButton}
              onClick={() => setOpenFilter(true)}
            >
              Filtrar
              <FilterListIcon sx={{ fontSize: 'small', marginLeft: '3px' }} />
            </Button>

            <Button variant="outlined" className={classes.actionButton} onClick={handleOpenActionOptions}>
              Ações <ArrowDropDown />
            </Button>

            {selectedGenerator && (
              <FeamStatusSign
                customerCnpj={selectedGenerator.cnpj}
                style={{
                  '&.MuiChip-root': {
                    height: 'auto',
                    borderRadius: '200px',
                    color: 'white',
                    marginRight: '10px',
                  },
                }}
              />
            )}
          </Grid>

          <Popover
            id="popover ações"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleCloseActionOptions}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <List>
              <ListItem button className={classes.actionButton} onClick={() => downloadMTRs(selectedGatheringIds)}>
                <ListItemText primary="Baixar MTRs" />
              </ListItem>
              <ListItem button className={classes.actionButton} onClick={() => downloadCDFs(selectedGatheringIds)}>
                <ListItemText primary="Baixar CDFs" />
              </ListItem>
            </List>
          </Popover>
        </Grid>
      )}
      <CancelGatheringDialog
        isOpen={isCancelGatheringDialogOpen}
        cancelGatheringDialogData={cancelGatheringDialogData}
        handleClose={handleClose}
        handleSave={handleSave}
      />
      <GatheringFilterDrawer
        open={openFilter}
        onClose={handleCloseFilter}
        generatorOptions={customersMap}
        transporterOptions={partnersMap}
        receiverOptions={partnersMap}
        setParams={setFilterParams}
        setPartnersInfo={setPartnersInfos}
        setGenerator={setSelectedGenerator}
        generator={selectedGenerator}
        setTransporter={setSelectedTransporter}
        transporter={selectedTransporter}
        setReceiver={setSelectedReceiver}
        receiver={selectedReceiver}
      />
      <div className={featureAccessCheck('mtr-filter') ? 'filter--open' : 'filter--closed'}>
        <DataTable
          data={gatherings}
          dataMap={gatheringsMap}
          loading={loading}
          compact
          totalItems={totalItems}
          pageSize={setPageSize}
          paginating={setPageIndex}
          onSort={onSort}
          config={{
            showPagination: true,
            initialSort: { key: 'manifestEmissionDate', type: 'desc' },
            emptyValue: '-',
          }}
          customColumns={[
            { label: 'MTR', content: (row: Gathering) => <MTRColumn row={row} /> },
            { label: 'CDF', content: (row: Gathering) => <CDFColumn row={row} /> },
            { label: 'Cancelar', content: (row: Gathering) => <CancelColumn row={row} /> },
          ]}
        />
      </div>
    </>
  )
}

export default GatheringList
