import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import ClientForm from '../ClientForm'

import { ClientFormType, CompanyMinimalInfo } from '../../../../models/Company/Company'
import AppContext, { AppContextType } from '../../../../AppContext'
import { defaultBreadCrumbItems } from '../../../../components/BreadCrumb/BreadCrumb'

import { saveClient, createClientContact, saveClienteFacade } from '../../../../services/Client/ClientService'
import { ClientRequestI } from '../../../../models/Client/Client'

import useYupValidationResolver from '../../../../utils/yup-validator-resolver'
import getValidationSchema from '../utils/companyValidationSchema'
import setRequestData, { setClientContactData } from '../utils/setRequestData'
import { ClientContract } from '../../../../models/Client/ClientContract'
import { createClientContract, saveCustomerContractFile } from '../../../../services/Client/ClientContractService'
import { getLoggedUser } from '../../../../services/Auth/TokenService'
import {
  getCustomersByEmployeeId,
  updateEmployeeCustomerConnections,
} from '../../../../services/Employee/EmployeeService'

type ContractFileParams = {
  customerIdentifier: string
  contractIdentifier: string
}

const NewClientForm: React.FC = () => {
  const validationSchema = getValidationSchema()
  const resolver = useYupValidationResolver(validationSchema)
  const form = useForm<ClientFormType>({ resolver, mode: 'onChange' })
  const { setIsShowLoading, showAlert, setItemsBreadCrumb, setTitle } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const navigate = useNavigate()
  const userInfo = getLoggedUser()
  const [userCompanies, setUserCompanies] = React.useState<string[]>([])

  const getUserEmployeeInformation = async (): Promise<void> => {
    if (userInfo) {
      const userEmployeeCompanies = await getCustomersByEmployeeId(userInfo.employeeId)
      setUserCompanies(userEmployeeCompanies.map((company: CompanyMinimalInfo) => company.identifier))
    } else {
      showAlert(
        'warning',
        'Não foi possível carregar informações do usuário e não será possível vincula-lo ao novo cliente.'
      )
    }
  }

  const requestCreateCompany = async (companyRequestInfo: ClientRequestI): Promise<string> => {
    setIsShowLoading(true)
    try {
      const response = await saveClient(companyRequestInfo)
      showAlert('success', 'Cliente registrado com sucesso')
      return response.identifier!
    } catch {
      setIsShowLoading(false)
      throw new Error('Erro ao criar novo cliente')
    }
  }

  const requestSaveFacade = async (identifier: string, file: File): Promise<void> => {
    setIsShowLoading(true)
    try {
      await saveClienteFacade(identifier, file)
      showAlert('success', 'Fachada salva com sucesso.')
    } catch {
      setIsShowLoading(false)
      throw new Error('Erro ao criar novo cliente')
    }
  }

  const requestSaveContacts = async (identifier: string, data: ClientFormType): Promise<string> => {
    const clientContacts = setClientContactData(data)
    await Promise.all(
      clientContacts.map(async (contact) => {
        try {
          if (contact.name !== '' && contact.name !== undefined) {
            await createClientContact(identifier, contact)
          }
        } catch {
          throw new Error('Erro ao salvar contatos')
        }
      })
    )
    return Promise.resolve(identifier)
  }

  const requestSaveContract = async (
    identifier: string,
    data: ClientFormType
  ): Promise<ContractFileParams | undefined> => {
    setIsShowLoading(true)
    const contractInfo: ClientContract = {
      cashbackPercentage: data.cashbackPercentage / 100,
      paymentTime: data.paymentTime,
      purchasedPlan: data.purchasedPlan,
      status: data.status ? 1 : 0,
      minimumValue: data.minimumValue,
      expirationDate: data.expirationDate,
    }
    try {
      const { identifier: contractIdentifier } = await createClientContract(contractInfo, identifier)
      if (contractIdentifier) {
        return Promise.resolve({ customerIdentifier: identifier, contractIdentifier })
      }
    } catch {
      throw new Error('Erro ao salvar informações do contrato')
    } finally {
      setIsShowLoading(false)
    }
    return Promise.resolve(undefined)
  }

  const requestSaveContractFile = async (contractFile: ContractFileParams | undefined): Promise<string> => {
    setIsShowLoading(true)
    if (!contractFile) {
      setIsShowLoading(false)
      return Promise.reject(new Error('Erro ao salvar contrato'))
    }
    const { customerIdentifier, contractIdentifier } = contractFile
    const file = form.getValues('contractFile')
    if (file) {
      try {
        await saveCustomerContractFile(customerIdentifier, contractIdentifier, file)
      } catch {
        throw new Error('Erro ao salvar contrato')
      } finally {
        setIsShowLoading(false)
      }
    }
    return Promise.resolve(customerIdentifier)
  }

  const connectNewCustomerToUser = async (customerIdentifier: string): Promise<string> => {
    if (userInfo) {
      const newUserCustomersList = [...userCompanies, customerIdentifier]
      await updateEmployeeCustomerConnections(newUserCustomersList as string[], userInfo.employeeId)
    }

    return Promise.resolve(customerIdentifier)
  }

  const onSubmit = (data: ClientFormType): void => {
    const companyRequestInfo = setRequestData(data)
    requestCreateCompany(companyRequestInfo)
      .then((identifier) => {
        const file = form.getValues('companysFacade')
        if (file) {
          requestSaveFacade(identifier, file)
        }
        return Promise.resolve(identifier)
      })
      .then((identifier) => requestSaveContacts(identifier, data))
      .then((identifier) => requestSaveContract(identifier, data))
      .then((contractFileInfo: ContractFileParams | undefined) => requestSaveContractFile(contractFileInfo))
      .then((identifier) => connectNewCustomerToUser(identifier))
      .then((identifier) => navigate(`/main/clients/form/${identifier}`))
      .catch((error: Error) => showAlert('error', error.message))
  }

  React.useEffect(() => {
    setTitle('Novo cliente')
    form.setValue('branch', 0)
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar Clientes', path: '/main/clients/list' },
      { label: 'Novo Cliente', path: '/main/clients/form' },
    ])
    getUserEmployeeInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ClientForm form={form} onSubmit={onSubmit} />
}

export default NewClientForm
