import * as yup from 'yup'
import { AnyObject } from 'yup/lib/types'
import { checkCnpjAvailability } from '../../../../services/Client/ClientService'
import { CnpjAvailabilityCheckRequest } from '../../../../models/Client/Client'

const handleCnpjRegisteredCheck = async (cnpj: string, identifier?: string): Promise<boolean> => {
  const cleanedValue = cnpj.replaceAll(/[^0-9]/g, '')
  if (cleanedValue.length !== 14) return true

  const cnpjCheckRequest: CnpjAvailabilityCheckRequest = {
    cnpj,
    ...(identifier && { identifier }),
  }

  try {
    const response = await checkCnpjAvailability(cnpjCheckRequest)
    return response.allowed
  } catch {
    return false
  }
}

const getValidationSchema = (identifier?: string): AnyObject => {
  return yup.object({
    companyName: yup.string().required('Nome é obrigatório'),
    cnpj: yup
      .string()
      .required('CNPJ é obrigatório')
      .test('checkCnpjAvailability', 'CNPJ já cadastrado', (value) =>
        handleCnpjRegisteredCheck(value as string, identifier)
      ),
    corporateName: yup.string().required('Razão social é obrigatório'),
    cep: yup.string().required('CEP é obrigatório'),
    state: yup.string().required('Estado é obrigatório'),
    city: yup.string().required('Cidade é obrigatório'),
    district: yup.string().required('Bairro é obrigatório'),
    street: yup.string().required('Logradouro é obrigatório'),
    branch: yup
      .number()
      .typeError('Favor inserir um número válido')
      .min(1, 'Valor invalido')
      .required('Filial é obrigatório'),
    cashbackPercentage: yup.number().required('Porcentagem de cashback é obrigatório'),
    paymentTime: yup.number().required('Tempo de pagamento é obrigatório'),
    purchasedPlan: yup.string().required('Plano de compras é obrigatório'),
    expirationDate: yup.string().required('Data de expiração é obrigatório'),
  })
}

export default getValidationSchema
